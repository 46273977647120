import request from '@/utils/request'

// list
export function cateList(params) {
    return request({
        url: '/cate/lst',
        method: 'get',
        params
    })
}

// 删除分类
export function cateDel(data)
{
    return request({
        url: '/cate/del',
        method: 'delete',
        data
    })
}

// add
export function cateAdd(data) {
    return request({
        url: '/cate/add',
        method: 'post',
        data
    })
}

// edit
export function cateEdit(data) {
    return request({
        url: '/cate/edit',
        method: 'post',
        data
    })
}

// info
export function cateInfo(params)
{
    return request({
        url: '/cate/info',
        method: 'get',
        params
    })
}
