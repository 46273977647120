<template>
  <div class="cate">
    <van-form @submit="onSubmit" class="form">
      <van-field
          v-model="form.name"
          label="菜单名称"
          required
		  maxlength="4"
          placeholder="菜单名称"
          :rules="[{ required: true, message: '请填写菜单名称' }]"
      />
      <van-field name="uploader" required label="菜单图片" :rules="[{ required: true, message: '请上传菜单图片' }]">
        <template #input>
          <van-uploader name="image" :before-delete="afterDeletes" :after-read="afterReads" multiple :max-count="1"  v-model="image" />
        </template>
      </van-field>
      <van-field
          v-model="form.rank"
          label="菜单顺序"
          type="number"
          placeholder="请填写菜单顺序"
      />
      <van-field name="switch" label="状态">
        <template #input>
          <van-switch v-model="checked" size="20" @change="switchChange"/>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          保存
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {uploadImage} from "@/api/common";
import { cateInfo, cateAdd, cateEdit } from "@/api/cate";
import {Toast} from "vant";
import http from '../../utils/config.js'

export default {
  name: "CateAdd",
  data() {
    return {
      form: {
        name:'',
        image:'',
        status: 1,
        rank: 1,
		imageUrl: http.imageUrl,
      },
      image: [],
      checked: true
    }
  },
  mounted() {
    if (this.$route.query.id) this.initData()
  },
  methods: {
    initData() {
      cateInfo({id: this.$route.query.id}).then((res)=>{
        this.form = res.data
        if (res.data.image) this.image = [{ url: 'https://server.esw235.cn/'+res.data.image }]
        this.checked = this.form.status === 1
      })
    },
    afterDeletes(file,detail) {
      switch (detail.name)
      {
        case 'image':
          this.form.image = ''
          this.image.splice(detail.index,1)
          break
      }
    },
    afterReads(file,detail) {
      const formData = new FormData();
      formData.append('file', file.file)
	  console.log(formData)
      uploadImage(formData).then((res)=>{
		  console.log(res)
        if (detail.name === 'image')
        {
          this.form.image = res.data.path
        }
      })
    },
    onSubmit() {
      if (this.$route.query.id) {
		  
        cateEdit(this.form).then((res)=>{
          Toast.success(res.msg)
          this.$router.go(-1)
        })
      }else {
        cateAdd(this.form).then((res)=>{
			// console.log(this.from)
          Toast.success(res.msg)
          this.$router.go(-1)
        })
      }
    },
    switchChange() {
      this.form.status = this.checked ? 1 : 0;
    }
  }
}
</script>

<style lang="less" scoped>
.cate {
  min-height: calc(100vh);
  .form {
    padding: 20px 20px 60px 20px;
    background-color: white;
  }
}
</style>
